// $(document).ready(function () {

/*/ ===== Scroll to Top ====  /*/
$(window).scroll(function () {
  if ($(this).scrollTop() >= 50) {        // If page is scrolled more than 50px
    $('#bannerArrowScrollTo').fadeIn(200);    // Fade in the arrow
  } else {
    $('#bannerArrowScrollTo').fadeOut(200);   // Else fade out the arrow
  }
});
$('#bannerArrowScrollTo').click(function () {      // When arrow is clicked
  $('html,body').animate({
    scrollTop: 0                       // Scroll to top of body
  }, 10);
});


// var lastScrollTop = 0; // Variable to store the last scroll position

// $(window).scroll(function () {
//   var currentScrollTop = $(this).scrollTop(); // Get the current scroll position

//   // Check if user is scrolling down
//   if (currentScrollTop > lastScrollTop) {
//     if (currentScrollTop >= 650) {
//       $('#DaysNav').addClass('bottom_fixed').removeClass('top_position'); // Add .bottom_class and remove .up_top
//     }
//   } else { // User is scrolling up
//     if (currentScrollTop <= 650) {
//       $('#DaysNav').removeClass('bottom_fixed').addClass('top_position'); // Add .up_top and remove .bottom_class
//     }
//   }
//   // Update the last scroll position
//   lastScrollTop = currentScrollTop;
// });

// }

// SOF OWL Carousel Zoom in 
// $(".full-owl-carousel").owlCarousel({
//   margin: 10,
//   loop: true,
//   dots: true,
//   nav: true,
//   items: 1,
//   autoplay: true,
//   onInitialized: addSlideNumbers, // Call the function to add slide numbers
//   navText: ["<i class='fa'><img src='assets/images/arrow-left-slider-zoom.svg' alt='left Slider'></i>", "<i class='fa'><img src='assets/images/arrow-right-slider-zoom.svg' alt='right Slider'></i>"]
// });


// SOF OWL Carousel 
$(".owl-carousel").owlCarousel({
  margin: 10,
  loop: true,
  dots: true,
  nav: true,
  items: 1,
  autoplay: true,
  onInitialized: addSlideNumbers, // Call the function to add slide numbers
  navText: ["<i class='fa'><img src='assets/images/arrow-left-slider.svg' alt='left Slider'></i>", "<i class='fa'><img src='assets/images/arrow-right-slider.svg' alt='right Slider'></i>"]
});

// SOF Your_Travel Slider css  
document.addEventListener('DOMContentLoaded', function () {
  const myCarouselElement = document.querySelector('#bootstrap-touch-slider')

  const carousel = new bootstrap.Carousel(myCarouselElement, {
    interval: 4000,
    pause: false,
    touch: false
  });




});
// EOF Your_Travel Slider css  


// $('#bootstrap-touch-slider').carousel({
//   interval: 3000,
//   pause: false
// })

// $(document).ready(function(){
//   var $owl = $('.owl-carousel');
//   if ($owl.children().length > 1) {
//       $owl.owlCarousel({
//           items: 1,
//           loop: ture,
//           dots: true,
//           margin: 10,
//           nav: true,
//           autoplay: true,
//           autoplayTimeout: 3000,
//           autoplayHoverPause: true,
//           onInitialized: addSlideNumbers, // Call the function to add slide numbers
//   navText : ["<i class='fa'><img src='assets/images/arrow-left-slider.svg' alt='left Slider'></i>","<i class='fa'><img src='assets/images/arrow-right-slider.svg' alt='right Slider'></i>"]
//       });
//   } else {
//     $owl.owlCarousel({
//       items: 1,
//       loop: false,
//       nav: false,
//       autoplay: false
//     });
//     // Apply some static style or settings if needed for a single item
//     $owl.addClass('single-item');

//   }
// });



var owl = $('.owl-carousel');

// Toggle Overlay on Image Click
owl.on('click', '.item img', function () {
  $(this).siblings('.overlay').toggle();
});

// Full Screen Button Click
$('.fullscreen').click(function () {
  $('.owl-carousel').toggleClass('fullscreen-mode');
});

// Full Screen Button Click
$('.fullscreen').click(function () {
  $('.owl-carousel').toggleClass('fullscreen-mode');
  $('.overlay').toggleClass('fullscreen-mode-overlay');
});

// Function to add slide numbers
function addSlideNumbers(event) {
  var carousel = event.target;
  $(carousel).find('.owl-item').each(function (index) {
    var slideNumber = index - 2;
    var slideNumberElement = $('<div class="slide-number">' + slideNumber + ' PHOTOS</div>');
    $(this).find('.number-photo').append(slideNumberElement);

  });
}


// var header = $("#DaysNav");
// var btns = $(header).find(".nav-item");

// for (var i = 0; i < btns.length; i++) {
//     btns[i].addEventListener("click", function () {
//         // Get all elements with the 'active' class
//         var activeElements = document.querySelectorAll('.active');

//         // Loop through each element and remove the 'active' class
//         activeElements.forEach(function (element) {
//             element.classList.remove("active");
//         });

//         // Add the 'active' class to the clicked element
//         this.classList.add("active");
//     });
// }


// SOF Full itinerary Day wise Active 
// var header = document.getElementById("DaysNav");
// var btns = header.getElementsByClassName("nav-item");
// var previousActiveLink = document.querySelector('.active');
// for (var i = 0; i < btns.length; i++) {
//   btns[i].addEventListener("click", function () {
//     var current = document.getElementsByClassName(" active");
//     previousActiveLink.classList.remove("active");
//     current[0].className = current[0].className.replace(" active", "");
//     this.className += " active";
//   });
// }
// EOF Full itinerary Day wise Active 


// EOF Nevigation link


// SOF Profile page password & confirm password JS 



function validateForm() {
  let password = document.getElementById('usr_password').value;
  let confirm_password = document.getElementById('usr_cnfrm_password').value;

  let passwordError = document.getElementById('passwordError');
  let confirmpasswordError = document.getElementById('confirmpasswordError');
  let successMessage = document.getElementById('successMessage');

  let valid = true;

  passwordError.innerHTML = '';
  confirmpasswordError.innerHTML = '';

  if (password === '') {
    passwordError.innerHTML = 'Password is required';
    valid = false;
  }

  if (confirm_password === '') {
    confirmpasswordError.innerHTML = 'Confirm Password is required';
    valid = false;
  }

  if (password !== '' && confirm_password !== '' && password !== confirm_password) {
    confirmpasswordError.innerHTML = 'Passwords don\'t match';
    valid = false;
  }

  if (valid) {
    successMessage.style.display = 'block';
    setTimeout(function () {
      successMessage.style.display = 'none';
      document.getElementById('changepasswordForm').reset();
    }, 2000);
  }

  return false;
}

function validatePassword() {

  if (password !== confirm_password) {
    confirmpasswordError.innerHTML = 'Passwords don\'t match';
  } else {
    confirmpasswordError.innerHTML = '';
  }
}

// document.getElementById('usr_password').onchange = validatePassword;
// document.getElementById('usr_cnfrm_password').onkeyup = validatePassword;









// 20th August

// SOF see more/see less

$(document).ready(function () {
  var showChar = 600;
  var ellipsestext = "...";
  var moretext = "Read More";
  var lesstext = "Read Less";
  $(".expandableL").each(function () {
    var content = $(this).html();
    if (content.length > showChar) {
      var show_content = content.substr(0, showChar);
      var hide_content = content.substr(showChar, content.length - showChar);
      var html =
        show_content +
        '<span class="moreelipses">' +
        ellipsestext +
        '</span><span class="remaining-content"><span>' +
        hide_content +
        '</span>&nbsp;&nbsp;<a href="" class="morelink">' +
        moretext +
        "</a></span>";
      $(this).html(html);
    }
  });

  $(".morelink").click(function () {
    if ($(this).hasClass("less")) {
      $(this).removeClass("less");
      $(this).html(moretext);
    } else {
      $(this).addClass("less");
      $(this).html(lesstext);
    }
    $(this).parent().prev().toggle(640);
    $(this).prev().toggle(640);
    return false;
  });
});



// EOF see more/see less  




